import React from "react";
import partner1 from "./../assets/img/canarias-zec.png";
import partner2 from "./../assets/img/gobierno-icon.png";
import partner3 from "./../assets/img/tenerife-partner.jpeg";
import partner4 from "./../assets/img/4-removebg-preview.png";
import { useTranslation } from "react-i18next";

const PartnetsSection = () => {
  const { t } = useTranslation();
  
  return (
    <section>
      <div className="container">
        <div className="py-20 md:px-10 px-4">
          <h1 className="text-black-text text-center mb-3">{t("Our Partners")}</h1>
          <p className="text-center text-black-text text-lg font-thin mb-10">
            {t("Beyond Partnerships: A Symphony of Success with Our Trusted Allies.")}
          </p>
          <div className="flex flex-col items-center lg:flex-row lg:justify-between gap-5 lg:gap-0">
            <div className="flex flex-col sm:flex-row gap-5">
              <a
                href="/"
                className="hover-animation block w-[150px] lg:w-[200px] h-[100px] lg:h-[150px] rounded-md"
              >
                <img
                  src={partner1}
                  alt="partner-icon"
                  className="w-full h-full object-cover rounded-md"
                />
              </a>
              <a
                href="/"
                className="hover-animation block w-[150px] lg:w-[200px] h-[100px] lg:h-[150px] rounded-md"
              >
                <img
                  src={partner2}
                  alt="partner-icon"
                  className="w-full h-full object-cover rounded-md"
                />
              </a>
            </div>
            <div className="flex flex-col sm:flex-row gap-5">
              <a
                href="/"
                className="hover-animation block w-[150px] lg:w-[200px] h-[100px] lg:h-[150px] rounded-md"
              >
                <img
                  src={partner3}
                  alt="partner-icon"
                  className="w-full h-full object-cover rounded-md"
                />
              </a>
              <a
                href="/"
                className="hover-animation flex w-[150px] lg:w-[200px] h-[100px] lg:h-[150px] rounded-md bg-white justify-center items-center"
              >
                <img
                  src={partner4}
                  alt="partner-icon"
                  className="w-full h-full object-cover rounded-md"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnetsSection;
